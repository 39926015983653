import feather from 'feather-icons';

import './module/core/bootstrap.bundle.min'
import './module/core/tiny-slider'
// import '../module/core/feather.min'
import './module/core/plugins.init'
import './module/core/app'

function toggleMenu() {
  document.getElementById('isToggle').classList.toggle('open');
  var isOpen = document.getElementById('navigation')
  if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
  } else {
      isOpen.style.display = "block";
  }
};

// ----------- header_banner -----------
const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

// const headerBanner = () => {
//   const name = 'header_banner_shopify';
//   const alertNode = document.querySelector('.header-banner');
//   console.log(getCookie(name));
//   if (!getCookie(name)) {
//     alertNode.style.display = 'block';
//   }
//   alertNode.addEventListener('closed.bs.alert', () => {
//     setCookie(name, Date.now());
//   });
// };
// -------------------------------------

const main = () => {
  feather.replace();

  const toggle = document.querySelector('#isToggle');
  if (toggle) {
    toggle.addEventListener('click', () => {
      toggleMenu();
    });
  }
};

document.addEventListener("DOMContentLoaded", () => {
  main();
  // headerBanner();
});
